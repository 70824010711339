<template>
  <div>
    <el-row :gutter="10">
      <el-col :md="14" :lg="12" :xl="10">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
        >
          <el-form-item
            :label="fields.type.label"
            :prop="fields.type.name"
            :required="fields.type.required"
          >
            <el-select v-model="model[fields.type.name]">
              <el-option :value="undefined">--</el-option>
              <el-option
                :key="option.id"
                :label="option.label"
                :value="option.id"
                v-for="option in fields.type.options"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="fields.name.label"
            :prop="fields.name.name"
            :required="fields.name.required"
          >
            <el-col>
              <el-input ref="focus" v-model="model[fields.name.name]" />
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.vorlage.label"
            :prop="fields.vorlage.name"
            :required="fields.vorlage.required"
          >
            <el-col>
              <app-file-upload
                :max="fields.vorlage.max"
                :storage="fields.vorlage.storage"
                :formats="fields.vorlage.formats"
                :permissions="fields.vorlage.permissions"
                v-model="model[fields.vorlage.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>

          <el-form-item>
            <div class="form-buttons">
              <el-button
                :disabled="saveLoading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
              >
                <app-i18n code="common.save"></app-i18n>
              </el-button>

              <el-button
                :disabled="saveLoading"
                @click="doCancel"
                icon="el-icon-fa-close"
              >
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { VorlageDienstnehmerModel } from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-model';

const { fields } = VorlageDienstnehmerModel;
const formSchema = new FormSchema([
  fields.id,
  fields.vorlage,
  fields.type,
  fields.name,
  fields.updatedAt,
]);

export default {
  name: 'app-vorlage-dienstnehmer-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  watch: {
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'vorlageDienstnehmer/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style scoped>
/* .el-form {
  width: 50%;
} */
</style>
